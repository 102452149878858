import React from 'react';
import AwardsAndRecognitionCarousel from '../../../Shared/AwardsAndRecognition/AwardsAndRecognitionCarousel';

function AwardsSection() {
  return (
    <section className="web-dev-old__awards-section">
      <div className="container">
        <div className="web-dev-old__awards-heading">Awards &amp; Recognitions</div>
        <p className="web-dev-old__awards-description">
          We’re proud to have picked these recognitions up throughout our journey. <br />
          To us, they’re more than trophies on a shelf, they represent our commitment to bringing
          the very best to our clients and their products.
        </p>
      </div>
      <div className="web-dev-old__awards-carousel">
        <AwardsAndRecognitionCarousel />
      </div>
    </section>
  );
}

export default AwardsSection;
