import {Link,useStaticQuery, graphql} from 'gatsby';
import {MDBAnimation} from 'mdbreact';
import React from 'react';

import Img from 'gatsby-image';
import ProjectCard from '../../../Shared/ProjectCard/ProjectCard';

const PROJECTS = [
  {
    id: 'life-print',
    featuredImageSrc: 'life-print.png',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/life-print.png').default,
    projectDescription:
      'Print living photos on the go with Lifeprint. A social combination of AR and portable printers to share your memories all over the world.',
    projectName: 'life-print',
    categories: {
      solutions: [
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [
        {
          value: 'News & Social Applications',
          label: 'News & Social Applications',
        },
        {
          value: 'Photo Editing and Printing',
          label: 'Photo Editing and Printing',
        },
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'iOS (Objective-C)', label: 'iOS (Objective-C)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'Google Application Engine',
          label: 'Google Application Engine',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
      ],
    },
  },
  {
    id: 'embr',
    featuredImageSrc: 'embr.png',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/embr.png').default,
    projectDescription:
      'Embr Labs’ heating and cooling bracelet that lets you take control & make yourself comfortable, anytime, anywhere through the connected application.',
    projectName: 'embr',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Quality Assurance', label: 'Quality Assurance'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'IoT Development', label: 'IoT Development'},
        {value: 'Cloud Services', label: 'Cloud Services'},
      ],
      industries: [
        {
          value: 'Healthcare & Life Sciences',
          label: 'Healthcare & Life Sciences',
        },
        {value: 'Lifestyle', label: 'Lifestyle'},
      ],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'Ruby on Rails', label: 'Ruby on Rails'},
        {value: 'NodeJS', label: 'NodeJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {
          value: 'IoT Software Development',
          label: 'IoT Software Development',
        },
        {
          value: 'Firebase Realtime Database',
          label: 'Firebase Realtime Database',
        },
        {value: 'BigQuery', label: 'BigQuery'},
      ],
    },
  },
  {
    id: 'raven',
    featuredImageSrc: 'raven.png',
    logoSrc: require('../../../../assets/images/portfolio/projects/logos/raven.png').default,
    projectDescription:
      'Raven is a platform for Real Estate Agents, by Real Estate Agents, who want to replace big brokerages taking huge money off Agent’s Leads and their commissions.',
    projectName: 'raven',
    categories: {
      solutions: [
        {value: 'Web Development', label: 'Web Development'},
        {value: 'Product Design', label: 'Product Design'},
        {value: 'Mobile Applications', label: 'Mobile Applications'},
        {value: 'Staff Augmentation', label: 'Staff Augmentation'},
        {value: 'Project Management', label: 'Project Management'},
      ],
      industries: [{value: 'Real Estate', label: 'Real Estate'}],
      technologies: [
        {value: 'Python', label: 'Python'},
        {value: 'ReactJS', label: 'ReactJS'},
        {value: 'iOS (Swift)', label: 'iOS (Swift)'},
        {value: 'Android (Kotlin)', label: 'Android (Kotlin)'},
        {value: 'Amazon Web Services', label: 'Amazon Web Services'},
      ],
    },
  },
];

function OurWorkSection() {
  const images = useStaticQuery(graphql`
    query {
      award_1: file(relativePath: {eq: "services/web-development/award-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_2: file(relativePath: {eq: "services/web-development/award-2.png"}) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_3: file(relativePath: {eq: "services/web-development/award-3.png"}) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      award_4: file(relativePath: {eq: "services/web-development/award-4.png"}) {
        childImageSharp {
          fluid(maxWidth: 240, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <section className="container web-dev-old-our-work__section">
      <div className="row">
        <div className="col-12 col-lg-5">
          <div className="web-dev-old-our-work__heading">Our Amazing Clients</div>
        </div>
        <div className="col-12 col-lg-7">
          <p className="web-dev-old-our-work__description">
            We believe in long term relationships, and continue to support the success of those that
            work with us. Here’s a glimpse at the amazing things they’ve been able to achieve.
          </p>
        </div>
      </div>
      <div className="row mb-5 justify-content-center">
        <div className="web-dev-old-our-work__award">
          <Img
            className="img-fluid"
            fluid={images.award_1.childImageSharp.fluid}
            alt="AARP's Innovation in Aging Award"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev-old-our-work__award">
          <Img
            className="img-fluid"
            fluid={images.award_2.childImageSharp.fluid}
            alt="CABHI Innovation"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev-old-our-work__award">
          <Img
            className="img-fluid"
            fluid={images.award_3.childImageSharp.fluid}
            alt="Apple Retail Partner"
            fadeIn={false}
            loading="lazy"
          />
        </div>
        <div className="web-dev-old-our-work__award">
          <Img
            className="img-fluid"
            fluid={images.award_4.childImageSharp.fluid}
            alt="Kids at Play Interactive"
            fadeIn={false}
            loading="lazy"
          />
        </div>
      </div>
      <div className="row">
        {PROJECTS.map((project, i) => (
          <MDBAnimation
            key={project.id}
            className="col web-dev-old-our-work__project-container"
            reveal
            delay={`${i * 50}ms`}
            type="fadeInUp"
          >
            <ProjectCard
              {...project}
              featuredImageLoading="lazy"
              projectDescriptionClass="web-dev-old-our-work__project-description"
            />
          </MDBAnimation>
        ))}
      </div>
    </section>
  );
}

export default OurWorkSection;
