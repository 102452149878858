import React from 'react';
import {MDBAnimation} from 'mdbreact';
import ClientLogoCard from './ClientLogoCard';

const LOGOS_DATA = [
  {
    name: 'bbc-worldwide.png',
    alt: 'Client Logo - BBC',
  },
  {
    name: 'bedbathbeyond.png',
    alt: 'Client Logo - Bed Bath and Beyond',
  },
  {
    name: 'careem.png',
    alt: 'Client Logo - Careem',
  },
  {
    name: 'drafkings.png',
    alt: 'Client Logo - Draft Kings',
  },
  {
    name: 'raven.png',
    alt: 'Client Logo - Raven',
  },
];

function ClientsLogoSection() {
  return (
    <section className="container web-dev-old-logos__containter">
      {LOGOS_DATA.map(({name, alt}, i) => (
        <MDBAnimation key={i} reveal type="fadeInUp">
          <ClientLogoCard image={name} alt={alt} />
        </MDBAnimation>
      ))}
    </section>
  );
}

export default ClientsLogoSection;
