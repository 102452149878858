import React from 'react';
import EngagementModelCard from '../../../Home/EngagementModel/EngagementModelCard';
import {ENGAGEMENT_MODELS_DATA} from '../../../Home/engagement_models';

function EngagementModelsSection() {
  return (
    <div className="container web-dev-old__engagement-models-section">
      <div className="row mb-5">
        <div className="col-12 col-lg-5">
          <div className="web-dev-old-engagement-models__heading">
            {ENGAGEMENT_MODELS_DATA.title}
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <p className="web-dev-old-engagement-models__description">
            {ENGAGEMENT_MODELS_DATA.description}
          </p>
        </div>
      </div>
      <div className="row web-dev-old-engagement-models-cards-container">
        {ENGAGEMENT_MODELS_DATA.cards.map((card, i) => (
          <div key={i} className="web-dev-old-engagement-models__card-container">
            <EngagementModelCard {...card} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default EngagementModelsSection;
