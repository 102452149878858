import {Link} from 'gatsby';
import React from 'react';

function ContactUsSection() {
  return (
    <div className="web-dev-old-contact__section">
      <div className="container web-dev-old-contact__container">
        <p className="web-dev-old-contact__title">Get in touch today!</p>
        <Link to="/contact" className="web-dev-old-contact__link">
          Start Your Project
        </Link>
      </div>
    </div>
  );
}

export default ContactUsSection;
