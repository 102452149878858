import React from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';

const TINTASH_NUMBERS = [
  {
    id: 1,
    number: '$750M',
    title: 'Funding Raised by Clients',
    description:
      'We’ve successfully helped some of the most innovative startups develop their initial products, helping them grow to become disruptively innovative companies within their industries.',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-1.png')
      .default,
  },
  {
    id: 2,
    number: '100+',
    title: 'Projects Delivered to Satisfied Clients',
    description:
      'Our expert teams have successfully delivered over 100 projects. Ranging from user centric Mobile and Web Applications, to full service solutions on custom architectures.',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-2.png')
      .default,
  },
  {
    id: 3,
    number: '150',
    title: 'Strong Team of Experts',
    description:
      'We continually grow our team of expert developers in each domain, ensuring that our clients get the very best talent for their needs and industry.',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-3.png')
      .default,
  },
  {
    id: 4,
    number: '2',
    title: 'Fabled Unicorns as Clients',
    description:
      'We’re proud to have been the development partners for some amazing startups, some of which have grown to become successful unicorns.',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-4.png')
      .default,
  },
  {
    id: 5,
    number: '3',
    title: 'Fortune 500 Clients',
    description:
      'Tintash continues to be the development partner of choice for key Fortune 500s, delivering multiple projects to them successfully throughout our partnership.',
    img: require('../../../../assets/images/services/web-development/tintash-numbers-5.png')
      .default,
  },
];

function RevenueSection() {
  const [curItemIndex, setCurItemIndex] = React.useState(0);
  const [items, setItems] = React.useState([]);

  const next = () => {
    setCurItemIndex(i => {
      if (i === TINTASH_NUMBERS.length - 1) {
        return 0;
      }
      return i + 1;
    });
  };

  const prev = () => {
    setCurItemIndex(i => {
      if (i === 0) {
        return TINTASH_NUMBERS.length - 1;
      }
      return i - 1;
    });
  };

  const moveTo = slide => {
    if (slide !== curItemIndex) {
      setCurItemIndex(slide);
    }
  };

  React.useEffect(() => {
    const _items = TINTASH_NUMBERS.slice(curItemIndex, curItemIndex + 3);
    if (_items.length !== 3) {
      _items.push(...TINTASH_NUMBERS.slice(0, 3 - _items.length));
    }
    setItems(_items);

    const intervalHandler = () => {
      next();
    };

    const id = setInterval(intervalHandler, 4000);

    return () => {
      clearInterval(id);
    };
  }, [curItemIndex]);

  return (
    <>
      <section className="web-dev-old__revenue-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 col-lg-5">
              <div className="web-dev-old-rev__heading">Our Journey so far</div>
            </div>
            <div className="col-12 col-lg-7">
              <p className="web-dev-old-rev__description">
                For over a decade, our web development teams have partnered with growing startups
                and global brands to improve, build and scale their web applications.
              </p>
            </div>
          </div>
        </div>
        <div className="container web-dev-old__revenue-container">
          <TransitionGroup component="div" className="web-dev-old-rev-carousel__container">
            {items.map((tinNum, i) => (
              <CSSTransition
                key={tinNum.id}
                classNames="web-dev-old-rev-carousel__card"
                in
                appear
                unmountOnExit
                timeout={1000}
              >
                <div className="web-dev-old-rev-grid__first-cell">
                  <div
                    className={`web-dev-old-rev-carousel__card web-dev-old-rev-carousel__card-${i}`}
                  >
                    <img src={tinNum.img} alt={tinNum.title} />
                    <div className="web-dev-old-rev-carousel__card-heading">{tinNum.number}</div>
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <div className="web-dev-old-rev-carousel__heading-container">
            <ul className="web-dev-old-rev-carousel__heading-list">
              <div>
                {TINTASH_NUMBERS.map((tinNum, i) => (
                  <div style={{display: curItemIndex === i ? undefined : 'none'}} key={tinNum.id}>
                    <div className="web-dev-old-rev-carousel__heading">{tinNum.title}</div>
                    <p className="web-dev-old-rev-carousel__description">{tinNum.description}</p>
                  </div>
                ))}
                <ul className="web-dev-old-rev-carousel__dots">
                  {TINTASH_NUMBERS.map((_, i) => (
                    <li
                      key={i}
                      onClick={() => {
                        moveTo(i);
                      }}
                      className={`web-dev-old-rev-carousel__dot ${
                        curItemIndex === i ? 'active' : ''
                      }`}
                     />
                  ))}
                </ul>
                <div className="web-dev-old-rev-carousel__indicators">
                  <button
                    onClick={next}
                    // disabled={curItemIndex === TINTASH_NUMBERS.length - 1}
                    className="web-dev-old-rev-carousel__indicator"
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.71217 7.69511L1.6777 13.7127C1.29383 14.0956 0.671463 14.0956 0.287784 13.7127C-0.0959263 13.33 -0.0959264 12.7094 0.287784 12.3268L5.62736 7.00217L0.287938 1.67774C-0.0957721 1.29494 -0.0957721 0.674385 0.287938 0.291739C0.671648 -0.0910616 1.29399 -0.0910616 1.67785 0.291739L7.71233 6.30938C7.90418 6.5008 8 6.75141 8 7.00214C8 7.25299 7.904 7.50378 7.71217 7.69511Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={prev}
                    // disabled={curItemIndex === 0}
                    className="web-dev-old-rev-carousel__indicator"
                  >
                    <svg
                      width="8"
                      height="14"
                      viewBox="0 0 8 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.287828 6.30953L6.3223 0.291979C6.70617 -0.0910081 7.32854 -0.0910081 7.71222 0.291979C8.09593 0.674624 8.09593 1.29524 7.71222 1.67786L2.37264 7.00247L7.71206 12.3269C8.09577 12.7097 8.09577 13.3303 7.71206 13.7129C7.32835 14.0957 6.70601 14.0957 6.32215 13.7129L0.287673 7.69525C0.0958184 7.50384 -5.89821e-07 7.25323 -6.11741e-07 7.0025C-6.33671e-07 6.75165 0.0960043 6.50086 0.287828 6.30953Z"
                        fill="currentColor"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}

export default RevenueSection;
