import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {MDBAnimation} from 'mdbreact';
import TechLogosGrid from './TechLogosGrid';

if (typeof window !== 'undefined') {
  require('bootstrap/js/dist/tab');
}

const TECH_STACKS = {
  frontEnd: [
    {
      img: 'our-expertise-fe-reactjs.png',
      title: 'React Js',
    },
    {
      img: 'our-expertise-fe-angular.png',
      title: 'Angular Js',
    },
    {
      img: 'our-expertise-fe-vuejs.png',
      title: 'Vue Js',
    },
    {
      img: 'our-expertise-fe-reactivex.png',
      title: 'ReactiveX',
    },
    {
      img: 'our-expertise-fe-jquery.png',
      title: 'jQuery',
    },
  ],
  backEnd: [
    {
      img: 'our-expertise-be-django.png',
      title: 'Django',
    },
    {
      img: 'our-expertise-be-ror.png',
      title: 'Ruby on Rails',
    },
    {
      img: 'our-expertise-be-node.png',
      title: 'Node Js',
    },
    {
      img: 'our-expertise-be-java.png',
      title: 'Java',
    },
    {
      img: 'our-expertise-be-golang.png',
      title: 'GoLang',
    },
  ],
  devOps: [
    {
      img: 'our-expertise-dev-aws.png',
      title: 'Amazon Web Services',
    },
    {
      img: 'our-expertise-dev-google.png',
      title: 'Google Cloud Platform',
    },
    {
      img: 'our-expertise-dev-firebase.png',
      title: 'Firebase / Cloud Firestorm',
    },
    {
      img: 'our-expertise-dev-heroku.png',
      title: 'Heroku',
    },
    {
      img: 'our-expertise-dev-docker.png',
      title: 'Docker & Kubernetes',
    },
    {
      img: 'our-expertise-dev-jenkins.png',
      title: 'Jenkins',
    },
  ],
};

function OurExpertiseSection() {
  const [coverIndex, setCoverIndex] = React.useState(1);
  const images = useStaticQuery(graphql`
    query {
      cover_1: file(relativePath: {eq: "services/web-development/our-expertise-cover-1.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cover_2: file(relativePath: {eq: "services/web-development/our-expertise-cover-2.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cover_3: file(relativePath: {eq: "services/web-development/our-expertise-cover-3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <section className="web-dev-old-our-expertise__section">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-5">
            <div className="web-dev-old-our-expertise__cover-container">
              {coverIndex === 1 && (
                <MDBAnimation type="fadeInLeft">
                  <Img
                    draggable="false"
                    imgStyle={{objectFit: 'contain'}}
                    placeholderStyle={{objectFit: 'contain'}}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    fluid={images.cover_1.childImageSharp.fluid}
                    alt="App Snapshot"
                    fadeIn={false}
                    loading="lazy"
                  />
                </MDBAnimation>
              )}
              {coverIndex === 2 && (
                <MDBAnimation type="fadeInLeft">
                  <Img
                    draggable="false"
                    imgStyle={{objectFit: 'contain'}}
                    placeholderStyle={{objectFit: 'contain'}}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    fluid={images.cover_2.childImageSharp.fluid}
                    alt="App Snapshot"
                    fadeIn={false}
                    loading="lazy"
                  />
                </MDBAnimation>
              )}
              {coverIndex === 3 && (
                <MDBAnimation type="fadeInLeft">
                  <Img
                    draggable="false"
                    imgStyle={{objectFit: 'contain'}}
                    placeholderStyle={{objectFit: 'contain'}}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    fluid={images.cover_3.childImageSharp.fluid}
                    alt="App Snapshot"
                    fadeIn={false}
                    loading="lazy"
                  />
                </MDBAnimation>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-7 pt-0 pt-lg-3">
            <h2 className="web-dev-old-our-expertise__heading">
              Our Web <span className="sr-only">Application</span> Development Expertise
            </h2>
            <p className="web-dev-old-our-expertise__description">
              Our team of web developers bring with them over a decade’s worth of experience. From
              working on complex architectures to developing responsive frontend applications, our
              team is skilled in delivering well integrated, leading-edge web solutions for your
              business.
            </p>
            <ul className="nav nav-tabs" id="web-dev-old-our-expertise-tab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="web-dev-old-our-expertise-fn-tab"
                  data-toggle="tab"
                  href="#fn"
                  role="tab"
                  aria-controls="fn"
                  aria-selected="true"
                  onClick={() => {
                    setCoverIndex(1);
                  }}
                >
                  <h3>
                    Frontend<span className="sr-only">Web App Development</span>
                  </h3>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="web-dev-old-our-expertise-be-tab"
                  data-toggle="tab"
                  href="#be"
                  role="tab"
                  aria-controls="be"
                  aria-selected="false"
                  onClick={() => {
                    setCoverIndex(2);
                  }}
                >
                  <h3>
                    <span className="sr-only">Web</span>Backend
                    <span className="sr-only">Development</span>
                  </h3>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="web-dev-old-our-expertise-devops-tab"
                  data-toggle="tab"
                  href="#devops"
                  role="tab"
                  aria-controls="devops"
                  aria-selected="false"
                  onClick={() => {
                    setCoverIndex(3);
                  }}
                >
                  <h3>
                    Cloud DevOps<span className="sr-only">Services</span>
                  </h3>
                </a>
              </li>
            </ul>
            <div className="tab-content" id="ar-industries-tab-content">
              <div
                className="tab-pane fade show active"
                id="fn"
                role="tabpanel"
                aria-labelledby="web-dev-old-our-expertise-fn-tab"
              >
                <MDBAnimation type="fadeInRight">
                  <TechLogosGrid techStacks={TECH_STACKS.frontEnd} />
                </MDBAnimation>
              </div>
              <div
                className="tab-pane fade"
                id="be"
                role="tabpanel"
                aria-labelledby="web-dev-old-our-expertise-be-tab"
              >
                <MDBAnimation type="fadeInRight">
                  <TechLogosGrid techStacks={TECH_STACKS.backEnd} />
                </MDBAnimation>
              </div>
              <div
                className="tab-pane fade"
                id="devops"
                role="tabpanel"
                aria-labelledby="web-dev-old-our-expertise-devops-tab"
              >
                <MDBAnimation type="fadeInRight">
                  <TechLogosGrid techStacks={TECH_STACKS.devOps} />
                </MDBAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OurExpertiseSection;
