import React from 'react';
import {Link,useStaticQuery, graphql} from 'gatsby';

import Img from 'gatsby-image';
import {MDBAnimation} from 'mdbreact';

function WhyUsSection() {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: {eq: "services/web-development/why-us-cover.png"}) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  return (
    <section className="container web-dev-old-why__container">
      <div className="row">
        <div
          className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-start"
        >
          <div className="web-dev-old-why-card__title">Why Tintash</div>
          <p className="web-dev-old-why-card__description">
            Combining their experience working for industry giants such as Apple and Paypal, Tintash
            is a team led by Stanford and Harvard Alumni that aims to bring the best of Silicon
            Valley to you.
          </p>
          <ul className="web-dev-old-why-card__checklist">
            <li className="web-dev-old-why-card__checklist-item">Carefully curated Agile Teams</li>
            <li className="web-dev-old-why-card__checklist-item">Remote network of Developers</li>
            <li className="web-dev-old-why-card__checklist-item">
              Time Zones Aligned with your Team
            </li>
            <li className="web-dev-old-why-card__checklist-item">
              Proven Track Record with 10+ Years In the Industry
            </li>
          </ul>
          <Link to="/contact" className="web-dev-old-why-card__link">
            Let’s Get Your Project Started!
          </Link>
        </div>
        <div className="col-12 col-md-6 my-auto">
          <div className="web-dev-old__img-cover-overlay">
            <div className="web-dev-old__landing-cover-overlay-1" />
            <div className="web-dev-old__landing-cover-overlay-2" />
            <div className="web-dev-old__landing-cover-overlay-3" />
            <div className="web-dev-old__landing-cover-overlay-4" />
            <div className="web-dev-old__landing-cover-overlay-5" />
            <MDBAnimation
              delay={100}
              className="web-dev-old__cover-animation"
              reveal
              type="fadeInUp"
            >
              <div className="web-dev-old__img-cover">
                <Img
                  imgStyle={{objectFit: 'contain'}}
                  placeholderStyle={{objectFit: 'contain'}}
                  objectFit="contain"
                  objectPosition="50% 50%"
                  fluid={data.cover.childImageSharp.fluid}
                  alt="Cover"
                  fadeIn={false}
                  loading="lazy"
                />
              </div>
            </MDBAnimation>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyUsSection;
