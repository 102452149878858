import React from 'react';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import Layout from '../../../components/Shared/Layout';
import '../../../assets/css/services/web-development-old.css';
import HeadingSection from '../../../components/Services/WebDevelopment/Old/HeadingSection';
import ClientsLogoSection from '../../../components/Services/WebDevelopment/Old/ClientsLogoSection';
import RevenueSection from '../../../components/Services/WebDevelopment/Old/RevenueSection';
import AwardsSection from '../../../components/Services/WebDevelopment/Old/AwardsSection';
import WhyUsSection from '../../../components/Services/WebDevelopment/Old/WhyUsSection';
import OurExpertiseSection from '../../../components/Services/WebDevelopment/Old/OurExpertiseSection';
import IndustrySection from '../../../components/Services/WebDevelopment/Old/IndustrySection';
import OurWorkSection from '../../../components/Services/WebDevelopment/Old/OurWorkSection';
import ClientReviewsSection from '../../../components/Services/WebDevelopment/Old/ClientReviewsSection';
import ContactUsSection from '../../../components/Services/WebDevelopment/Old/ContactUsSection';
import EngagementModelsSection from '../../../components/Services/WebDevelopment/Old/EngagementModelsSection';
import withUTMParams from '../../../components/HOC/withUTMParams';

function WebDevelopmentService({location}) {
  React.useEffect(() => {
    ReactGA.set({
      title: 'Services/Web.Development.Old',
    });
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <div className="web-dev-old__page">
      <Layout location={location} headerPosition="fixed">
        <Helmet>
          <title>Industry Leading Web Application Development Company | Tintash</title>
          <meta
            name="description"
            content="Tintash is an Industry Leading Web application development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
          />
        </Helmet>
        <HeadingSection />
        <ClientsLogoSection />
        <AwardsSection />
        <OurExpertiseSection />
        <OurWorkSection />
        <WhyUsSection />
        <RevenueSection />
        <IndustrySection />
        <EngagementModelsSection />
        <ClientReviewsSection />
        <ContactUsSection />
      </Layout>
    </div>
  );
}

export default withUTMParams(WebDevelopmentService);
