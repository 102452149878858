import React from 'react';
import {Link,useStaticQuery, graphql} from 'gatsby';

import Img from 'gatsby-image';

function HeadingSection() {
  const data = useStaticQuery(graphql`
    query {
      cover: file(relativePath: {eq: "services/web-development/landing-cover.png"}) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <section className="container">
      <div className="row web-dev-old__header-section">
        <div className="col-12 col-lg-5 mb-5 mb-lg-0">
          <h1 className="web-dev-old__header-heading">
            Industry Leading Web Application Development
            <span className="sr-only">Company</span>
          </h1>
          <div className="web-dev-old__header-description">
            <h2>Tintash is a leading web application development company</h2>, specialised in
            developing custom frontend and backend software solutions.
          </div>
          <div>
            <Link to="/contact" className="web-dev-old__header-link">
              Let’s Get Started!
            </Link>
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <div className="web-dev-old__landing-cover-overlay">
            <div className="web-dev-old__landing-cover-overlay-1" />
            <div className="web-dev-old__landing-cover-overlay-2" />
            <div className="web-dev-old__landing-cover-overlay-3" />
            <div className="web-dev-old__landing-cover-overlay-4" />
            <div className="web-dev-old__landing-cover-overlay-5" />
            <div className="web-dev-old__landing-cover">
              <Img
                imgStyle={{objectFit: 'contain'}}
                placeholderStyle={{objectFit: 'contain'}}
                objectFit="contain"
                objectPosition="50% 50%"
                fluid={data.cover.childImageSharp.fluid}
                alt="Camaradly Frontend App Development"
                fadeIn={false}
                loading="eager"
                className="web-dev-old__slide-down"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
