import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {MDBAnimation} from 'mdbreact';
import IndustryCard from './IndustryCard';

function IndustrySection() {
  const images = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: {eq: "services/web-development/industry-1.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_2: file(relativePath: {eq: "services/web-development/industry-2.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_3: file(relativePath: {eq: "services/web-development/industry-3.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_4: file(relativePath: {eq: "services/web-development/industry-4.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      image_5: file(relativePath: {eq: "services/web-development/industry-5.jpg"}) {
        childImageSharp {
          fixed(width: 220, quality: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <section className="web-dev-old-industry__section">
      <div className="container web-dev-old-industry__container">
        <div className="row mb-5">
          <div className="col-12 col-lg-5">
            <div className="web-dev-old-industry__heading">Industry Verticals</div>
          </div>
          <div className="col-12 col-lg-7">
            <p className="web-dev-old-industry__description">
              With over 10 years of experience, our web development teams have had the pleasure of
              partnering with some amazing clients and leading global brands to build and scale
              their products across a wide range of industries.
            </p>
          </div>
        </div>
        <div className="web-dev-old-industry__cards-container">
          <MDBAnimation className="web-dev-old-industry__card-animation" reveal type="fadeInUp">
            <IndustryCard fixed={images.image_1.childImageSharp.fixed} title="Manufacturing" />
          </MDBAnimation>
          <MDBAnimation
            className="web-dev-old-industry__card-animation"
            delay="50ms"
            reveal
            type="fadeInUp"
          >
            <IndustryCard fixed={images.image_2.childImageSharp.fixed} title="Financial Services" />
          </MDBAnimation>
          <MDBAnimation
            className="web-dev-old-industry__card-animation"
            reveal
            delay="100ms"
            type="fadeInUp"
          >
            <IndustryCard
              fixed={images.image_3.childImageSharp.fixed}
              title="Workplace Management"
            />
          </MDBAnimation>
          <MDBAnimation
            className="web-dev-old-industry__card-animation"
            reveal
            delay="150ms"
            type="fadeInUp"
          >
            <IndustryCard fixed={images.image_4.childImageSharp.fixed} title="Lifestyle" />
          </MDBAnimation>
          <MDBAnimation
            className="web-dev-old-industry__card-animation"
            reveal
            delay="200ms"
            type="fadeInUp"
          >
            <IndustryCard
              fixed={images.image_5.childImageSharp.fixed}
              title="Retail &amp; eCommerce"
            />
          </MDBAnimation>
        </div>
      </div>
    </section>
  );
}

export default IndustrySection;
