import React from 'react';
import TechStackLogo from './TechStackLogo';

function TechLogosGrid({techStacks}) {
  return (
    <div className="web-dev-old-our-expertise__stack-grid">
      {techStacks.map(stack => (
        <div className="web-dev-old-our-expertise__stack-grid-item" key={stack.title}>
          <div className="web-dev-old-our-expertise__stack-img">
            <TechStackLogo src={stack.img} alt={stack.title} />
          </div>
          <div className="web-dev-old-our-expertise__stack-title">
            {stack.title}
            <span className="sr-only">Development</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TechLogosGrid;
